import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ThankYou = styled.div`
  min-height: 50vh;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function ThankYouPage() {
  return (
    <ThankYou>
      <h1>Thank you for contacting us!</h1>
      <h4>We will get back to you as soon as possible!</h4>
      <Link
        to="/"
        className="mt-8 md:mr-4 px-4 py-3 leading-none border rounded-full hover:bg-orange-theme hover:text-white border-orange-theme bg-transparent text-orange-theme"
      >
        Back to Homepage
      </Link>
    </ThankYou>
  );
}
